import { IStudent, StudentField } from '@studyportals/studentdomain';
import { InterestType } from '../../../interfaces/enumerations';
import { StudentRepository } from '../student-repository';

export abstract class StudentRepositoryState {
	constructor(
		protected eventAggregationService: any,
		protected sessionService: any,
		protected studentRepository: StudentRepository,
	) {

	}

	public abstract initialize(): void;

	public abstract async setStudentData(studentData: IStudent): Promise<void>;
	public abstract async getStudentData(studentFields: StudentField[]): Promise<IStudent>;
	public abstract async addDisciplines(ids: number[]): Promise<void>;
	public abstract async removeDisciplines(ids: number[]): Promise<void>;
	public abstract async addInterest(type: InterestType, ids: number[]): Promise<void>;
	public abstract async removeInterest(type: InterestType, ids: number[]): Promise<void>;

}
