import {
	IEventAggregationService,
	ISubscriber,
	ITokenBasedSessionService,
	SessionCreatedEvent,
} from '@studyportals/student-interfaces';
import { ISession } from '@studyportals/student-interfaces/session-management/session.interface';
import { IStudent, StudentField } from '@studyportals/studentdomain';
import { InterestType, StudentRepositoryStateType } from '../../../interfaces/enumerations';
import { AnonymousStudentProfileSynced } from '../../../interfaces/events';
import { CatchReportAsyncException, CatchReportException } from '../../decorators/error-decorators';
import { AnonymousStudentEventBroadcaster } from '../../infrastructure/anonymous-student-event-broadcaster';
import { LocalStudentClient } from '../../infrastructure/clients/local-student-client';
import { StudentRepository } from '../student-repository';
import { OnlineStudentRepositoryState } from './online-student-repository-state';
import { StudentRepositoryState } from './student-repository-state';

export class OfflineStudentRepositoryState extends StudentRepositoryState implements ISubscriber<SessionCreatedEvent>  {

	private studentClient: LocalStudentClient;
	private anonymousStudentEventBroadcaster: AnonymousStudentEventBroadcaster;

	constructor(
		protected eventAggregationService: IEventAggregationService,
		protected sessionService: ITokenBasedSessionService,
		protected studentRepository: StudentRepository,
	) {
		super(eventAggregationService, sessionService, studentRepository);
		const anonymousStudentEventBroadcaster = new AnonymousStudentEventBroadcaster(eventAggregationService);
		this.anonymousStudentEventBroadcaster = anonymousStudentEventBroadcaster;
		this.studentClient = new LocalStudentClient('AnonymousStudent/Offline', 2629800, anonymousStudentEventBroadcaster);
	}

	@CatchReportException
	public initialize(): void {
		this.eventAggregationService.subscribeTo(SessionCreatedEvent.EventType, this)
	}

	@CatchReportAsyncException
	public async notify(sessionCreatedEvent: SessionCreatedEvent): Promise<void> {
		await this.onSessionCreatedEvent(sessionCreatedEvent.session);
	}

	private async onSessionCreatedEvent(session: ISession): Promise<void> {
		const state = this.createNewOnlineStudentRepositoryState();
		state.initialize();

		this.studentRepository.updateState(state);

		await this.syncData(state);
		this.broadcastSyncEvent();
	}

	private createNewOnlineStudentRepositoryState(): OnlineStudentRepositoryState {
		return new OnlineStudentRepositoryState(this.eventAggregationService, this.sessionService, this.studentRepository);
	}

	public async syncData(state: OnlineStudentRepositoryState): Promise<void> {
		await this.saveOldDataOnNewState(state);
		await this.studentClient.cleanUp();
	}

	private async saveOldDataOnNewState(state: OnlineStudentRepositoryState): Promise<void> {
		const allFields = this.getAllStudentFields();
		const localData = await this.getStudentData(allFields);

		const fieldsToSync = this.getFieldsToSync(localData);

		if (fieldsToSync.length === 0) {
			return;
		}

		const dataToSync = {};

		fieldsToSync.forEach((field) => {
			dataToSync[field] = localData[field];
		});

		await state.setStudentData(dataToSync);

	}

	private broadcastSyncEvent(): void {
		const event = new AnonymousStudentProfileSynced(new Date(), StudentRepositoryStateType.ONLINE);
		this.anonymousStudentEventBroadcaster.broadcastStudentProfileSyncedEvent(event);
	}

	private getFieldsToSync(data: IStudent): StudentField[] {
		return Object.keys(data).filter((field) => {
			return data[field] !== undefined;
		}) as StudentField[];
	}

	private getAllStudentFields(): StudentField[] {
		let studentFields: StudentField[] = [];

		const allKeyFields = Object.keys(StudentField);
		allKeyFields.forEach((keyField) => {
			studentFields.push(StudentField[keyField]);
		});

		return studentFields;
	}

	public async setStudentData(studentData: IStudent): Promise<void> {
		await this.studentClient.setData(studentData);
	}

	public async getStudentData(studentFields: StudentField[]): Promise<IStudent> {
		return this.studentClient.getData(studentFields);
	}

	public async addDisciplines(ids: number[]): Promise<void> {
		return this.studentClient.addDisciplines(ids);
	}

	public async removeDisciplines(ids: number[]): Promise<void> {
		return this.studentClient.removeDisciplines(ids);
	}

	public async addInterest(type: InterestType, ids: number[]): Promise<void> {
		return this.studentClient.addInterest(type, ids);
	}

	public async removeInterest(type: InterestType, ids: number[]): Promise<void> {
		return this.studentClient.removeInterest(type, ids);
	}
}
