import { DataStorage } from '@studyportals/data-storage';
import { IStudent, StudentField } from '@studyportals/studentdomain';
import { InterestType, StudentRepositoryStateType } from '../../../interfaces/enumerations';
import { AnonymousStudentProfileUpdated } from '../../../interfaces/events';
import { AnonymousStudentEventBroadcaster } from '../anonymous-student-event-broadcaster';
import { StudentClient } from '../interfaces/student-client';

export class LocalStudentClient implements StudentClient {

	// tslint:disable: typedef
	constructor(
		private storageKey: string,
		private storageTTL: number,
		private anonymousStudentEventBroadcaster: AnonymousStudentEventBroadcaster | null = null,
	) {

	}

	private localStorage = DataStorage;

	public async cleanUp(): Promise<void> {
		this.localStorage.remove(this.storageKey);
	}

	public async getData(studentFields: StudentField[]): Promise<IStudent> {
		const dataFromLocalStorage = this.retrieveDataFromLocalStorage();

		const studentData = {};
		studentFields.forEach((studentField) => {
			const dataField = dataFromLocalStorage[studentField];
			if (dataField === undefined) {
				return;
			}

			if (dataField === null) {
				studentData[studentField] = undefined;
				return;
			}

			studentData[studentField] = dataField;
		});

		return studentData;
	}

	public async setData(studentData: IStudent): Promise<void> {
		const dataForLocalStorage = this.retrieveDataFromLocalStorage();
		studentData = {...dataForLocalStorage, ...studentData};

		Object.keys(studentData).forEach((key) => {
			if (studentData[key] === null) {
				studentData[key] = undefined;
			}
		});

		this.setDataInLocalStorage(studentData);
		this.broadcastChanges(studentData);
	}

	public async addDisciplines(ids: number[]): Promise<void> {
		this.addToIdListInLocalStorage(StudentField.DISCIPLINES, ids);
	}

	public async addInterest(type: InterestType, ids: number[]): Promise<void> {
		this.addToIdListInLocalStorage(type as any as StudentField, ids);
	}

	public async removeDisciplines(ids: number[]): Promise<void> {
		this.removeFromIdListInLocalStorage(StudentField.DISCIPLINES, ids);
	}

	public async removeInterest(type: InterestType, ids: number[]): Promise<void> {
		this.removeFromIdListInLocalStorage(type, ids);
	}

	private retrieveDataFromLocalStorage() {
		const rawData = this.localStorage.retrieve(this.storageKey);
		const data = rawData ? JSON.parse(rawData) : {};

		return data;
	}

	private setDataInLocalStorage(data: object): any {
		Object.keys(data).forEach((key) => {
			if (data[key] === undefined) {
				data[key] = null;
			}
		});

		this.localStorage.store(this.storageKey, JSON.stringify(data), this.storageTTL);
	}

	private addToIdListInLocalStorage(key: StudentField | InterestType, ids: number[]): void {
		const dataForLocalStorage = this.retrieveDataFromLocalStorage();
		const savedItems = new Set(dataForLocalStorage[key]);
		ids.forEach((id) => {
			savedItems.add(id);
		});

		dataForLocalStorage[key] = Array.from(savedItems);
		this.setDataInLocalStorage(dataForLocalStorage);
		this.broadcastChanges({[key]: dataForLocalStorage[key]});
	}

	private removeFromIdListInLocalStorage(key: StudentField | InterestType, ids: number[]): void {
		const dataForLocalStorage = this.retrieveDataFromLocalStorage();
		const savedItems = new Set(dataForLocalStorage[key]);
		ids.forEach((id) => {
			savedItems.delete(id);
		});

		dataForLocalStorage[key] = Array.from(savedItems);
		this.setDataInLocalStorage(dataForLocalStorage);
		this.broadcastChanges({[key]: dataForLocalStorage[key]});
	}

	private broadcastChanges(changes: IStudent) {
		if (!this.anonymousStudentEventBroadcaster) {
			return;
		}

		const event = new AnonymousStudentProfileUpdated(new Date(), StudentRepositoryStateType.OFFLINE, changes);
		this.anonymousStudentEventBroadcaster.broadcastProfileUpdatedEvent(event);
	}

}
