import { IEventAggregationService, ISubscriber, ITokenBasedSessionService, SessionServiceReadyEvent } from '@studyportals/student-interfaces';
import { IStudent, StudentField } from '@studyportals/studentdomain';
import { InterestType } from '../../../interfaces/enumerations';
import { CatchReportAsyncException, CatchReportException } from '../../decorators/error-decorators';
import { Deferred } from '../deferred';
import { StudentRepository } from '../student-repository';
import { OfflineStudentRepositoryState } from './offline-student-repository-state';
import { OnlineStudentRepositoryState } from './online-student-repository-state';
import { StudentRepositoryState } from './student-repository-state';

export class PendingStudentRepositoryState extends StudentRepositoryState implements ISubscriber<SessionServiceReadyEvent>  {
	private state?: StudentRepositoryState;
	private lock: Deferred;

	constructor(
		eventAggregationService: IEventAggregationService,
		sessionService: ITokenBasedSessionService,
		studentRepository: StudentRepository,
	) {
		super(eventAggregationService, sessionService, studentRepository);
		this.lock = new Deferred();
	}

	@CatchReportException
	public initialize(): void {
		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true)
	}

	@CatchReportAsyncException
	public async notify(event: SessionServiceReadyEvent): Promise<void> {
		await this.onSessionServiceReady(event.sessionService);
	}

	public async setStudentData(studentData: IStudent): Promise<void> {
		await this.lock.untilOpen();
		return this.state!.setStudentData(studentData);
	}

	public async getStudentData(studentFields: StudentField[]): Promise<IStudent> {
		await this.lock.untilOpen();
		return this.state!.getStudentData(studentFields);
	}

	public async addDisciplines(ids: number[]): Promise<void> {
		await this.lock.untilOpen();
		return this.state!.addDisciplines(ids);
	}

	public async removeDisciplines(ids: number[]): Promise<void> {
		await this.lock.untilOpen();
		return this.state!.removeDisciplines(ids);
	}

	public async addInterest(type: InterestType, ids: number[]): Promise<void> {
		await this.lock.untilOpen();
		return this.state!.addInterest(type, ids);
	}

	public async removeInterest(type: InterestType, ids: number[]): Promise<void> {
		await this.lock.untilOpen();
		return this.state!.removeInterest(type, ids);
	}

	private async onSessionServiceReady(sessionService: any): Promise<void> {

		const session = await sessionService.getSession();

		const online = this.createOnlineStudentRepositoryState(sessionService);
		const offline = this.createOfflineStudentRepositoryState(sessionService);
		this.state = (session === null) ? offline : online;

		this.state.initialize();
		this.lock.open();

		this.studentRepository.updateState(this.state);

		if (this.state === online) {
			await offline.syncData(online);
		}
	}

	private createOnlineStudentRepositoryState(sessionService: any): OnlineStudentRepositoryState {
		return new OnlineStudentRepositoryState(this.eventAggregationService, sessionService, this.studentRepository);
	}

	private createOfflineStudentRepositoryState(sessionService: any): OfflineStudentRepositoryState {
		return new OfflineStudentRepositoryState(this.eventAggregationService, sessionService, this.studentRepository);
	}
}
