import { IEventAggregationService, ITokenBasedSessionService } from '@studyportals/student-interfaces';
import { IStudent, StudentField } from '@studyportals/studentdomain';
import { InterestType } from '../../interfaces/enumerations';
import { PendingStudentRepositoryState } from './states/pending-student-repository-state';
import { StudentRepositoryState } from './states/student-repository-state';

export class StudentRepository {

	private state: StudentRepositoryState;

	constructor(eventAggregationService: IEventAggregationService, sessionService: ITokenBasedSessionService) {
		this.state = this.createPendingStudentRepositoryState(eventAggregationService, sessionService);
		//
	}

	public initialize(): void {
		this.state.initialize();
	}

	private createPendingStudentRepositoryState(eventAggregationService: IEventAggregationService, sessionService: ITokenBasedSessionService): StudentRepositoryState {
		return new PendingStudentRepositoryState(eventAggregationService, sessionService, this);
	}

	public async setStudentData(studentData: IStudent): Promise<void> {
		await this.state.setStudentData(studentData);
	}

	public async getStudentData(studentFields: StudentField[]): Promise<IStudent> {
		const studentData = await this.state.getStudentData(studentFields);
		const studentDataKeys = Object.keys(studentData);

		studentFields.forEach((field) => {
			if (!studentDataKeys.includes(field)) {
				studentData[field] = undefined;
			}
		});

		return studentData;
	}

	public async getStudentDataCompleteness(studentFields: StudentField[]): Promise<number> {
		const studentData = await this.getStudentData(studentFields);

		const completeFields = studentFields.filter((field) => {
			return studentData[field] !== undefined;
		});

		return completeFields.length / studentFields.length;
	}

	public async addDisciplines(ids: number[]): Promise<void> {
		return this.state.addDisciplines(ids);
	}

	public async removeDisciplines(ids: number[]): Promise<void> {
		return this.state.removeDisciplines(ids);
	}

	public async addInterest(type: InterestType, ids: number[]): Promise<void> {
		return this.state.addInterest(type, ids);
	}

	public async removeInterest(type: InterestType, ids: number[]): Promise<void> {
		return this.state.removeInterest(type, ids);
	}

	public async setGPA(grade_type: string, grade_value: any): Promise<void> {

		let data = {
			gpa: {
				[grade_type]: grade_value,
				current_type: grade_type,
			},
		};

		return this.setStudentData(data);
	}

	public async setName(name: string): Promise<void> {
		let firstSpace = name.indexOf(' ', 0);
		let first_name: string | undefined;
		let last_name: string | undefined;

		if (firstSpace !== -1) {
			first_name = name.substring(0, firstSpace);
			last_name = name.substring(firstSpace).trim();
		} else {
			first_name = name;
			last_name = undefined;
		}

		let data = {
			name,
			first_name,
			last_name,
		};

		return this.setStudentData(data);
	}

	public updateState(state: StudentRepositoryState): void {
		this.state = state;
	}
}
